<template>
<b-container fluid>
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img :src="orgData.org_profile_img" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>
      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="orgData.org_cover_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="orgData.org_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="12" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="orgData.org_cover_img" style="width:100%" />
        </iq-card>
      </b-col>
      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-2">
            <span class="primary-color">{{orgData.org_name}}</span>
          </h3>
          <h3 class="mb-0">
            Reset Password
          </h3>
          <p>
            Enter the new password.
          </p>
          <ValidationObserver ref="resetForm">
            <form>
              <div class="form-group">
                <label for="otp">Password Reset OTP:</label>
                <ValidationProvider name="Activation Code" rules="required" v-slot="{ errors }">
                  <input type="Password" class="form-control" id="npass" v-model="otp" placeholder="Enter OTP Here">
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label for="npass">New Password:</label>
                <ValidationProvider name="New Password" rules="required" v-slot="{ errors }">
                  <input type="Password" class="form-control" id="npass" v-model="newPassword">
                  <span class="text-danger">{{ errors[0] }}</span>
                  <span class="text-danger" v-if=" confirmPassword && newPassword  && newPassword !== confirmPassword">Password must be same. Confirm password doesn't match</span>
                </ValidationProvider>
              </div>
              <div class="form-group">
                <label for="vpass">Verify Password:</label>
                <ValidationProvider name="Confirm Password" rules="required" v-slot="{ errors }">
                  <input type="Password" class="form-control" id="vpass" v-model="confirmPassword">
                  <span class="text-danger" >{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="" style="text-align: right;">
                <button type="button" @click="changePassword" class="btn btn-primary mr-2">
                  Submit
                </button>
              </div>
              <div class="sign-info" style="text-align:right">
                <span class="dark-color d-block line-height-2" style="text-align:left">Already Have An Account ? <router-link :to="{ path: 'login'}">Sign In</router-link></span>
                <ul class="iq-social-media mt-4">
                  <li>
                    <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                  </li>
                  <li>
                    <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                  </li>
                </ul>
              </div><!-- sign-info -->
            </form>
          </ValidationObserver>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
  .sign-info{
    margin-top:0px;
    border: 0px;
    padding-top:0px;
  }
</style>

<script>
import { ValidationProvider } from "vee-validate"
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"
import { Organisations } from "../FackApi/api/organisation.js"

export default {
  name: "ResetPassword",
  components: {
    ValidationProvider
  },
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Reset Password",
    showPasswordField: false,
    email: "",
    otp: "",
    newPassword: "",
    confirmPassword: "",
    token: "",
    errorFlag: false,
    operatingsystem: window.__DEVICE_OS__,
    orgData: {
      id: 54238,
      org_id: "GIDE_ORG_1",
      user_id: "1",
      acc_id: "1",
      org_name: "GIDE.AI",
      type: "ORG",
      org_desc: `<h1><strong style="color: rgb(226, 38, 44);">GIDE.AI</strong><strong>: </strong>Your Gateway to Scholarships and Career Success</h1><p><strong style="color: rgb(230, 0, 0);">Brought to you by Asia's largest Student and Parent community - High School Moms (HSM)</strong></p><p>Access futuristic career assessment, profile building, test preparation, great scholarships, and career guidance all in one integrated experience.</p><p><br></p>`,
      org_website_email: null,
      org_admin_email: "admin@gide.ai",
      org_admision_email: "admissions@gide.ai",
      org_admission_requirement: null,
      org_top_alums: null,
      org_major_industries_around: null,
      org_clubs: null,
      org_college_basis_app: null,
      org_city: null,
      org_country: null,
      org_founding_year: null,
      org_deadline: null,
      org_intake_count: null,
      org_intake_type: null,
      org_currency: null,
      org_avg_tuition_min: null,
      org_avg_tuition_max: null,
      org_scholarship_min: null,
      org_scholarship_max: null,
      org_tnc: 0,
      created_on: "2023-11-22T23:26:47.000Z",
      deleted: 0,
      country_id: null,
      org_profile_img: "https://web.gide.ai/img/GideWhiteNRed.2533ee45.jpeg",
      org_cover_img: "https://gide-assets.s3.amazonaws.com/GIDECover.White.png",
      org_state: null,
      org_uid: "",
      org_domain: "web.gide.ai",
      org_domain_is_validated: 99,
      country_name: null,
      currency_name: null,
      currency_code: null
    },
    propOpenedInModal: false
  }),
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    saasOrgData () {
      return this.$store.getters["SaasOrgData/getSaasOrgData"]
    }
  },
  async mounted () {
    await this.getOrgDetails()
    this.token = this.$router.query ? this.$router.query.token : " "
    this.userEmail = this.userData.user_email
  },
  methods: {
    /**
     * getOrgDetails
     */
    async getOrgDetails () {
      try {
        // Do not get the org_details if running on localhost, for localdns we have to set the org_domain in DB
        if (process.env.VUE_APP_ENV == "local") {
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
          return
        }

        const orgDomain = window.location.hostname
        if (this.saasOrgData) {
          // Found in Cache
          this.orgData = this.saasOrgData
        }
        else {
          const orgDetails = await Organisations.organisationView(this, this.orgId, orgDomain)
          if (!orgDetails.resp_status) {
            // set the details of GIDE_ORG_1 as default
            this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)
            return
          }

          this.orgData = orgDetails.resp_data.data
          // set the org data in local storage for saving in user table
          this.$store.dispatch("SaasOrgData/setSaasOrgData", this.orgData)

          // set the source for the user
          let source = this.orgData.org_uid
          if (this.sourceData) {
            source = this.orgData.org_uid + "_" + this.sourceData
          }

          this.$store.dispatch("SourceTracker/setCodeAction", source)
        }
      }
      catch (err) {
        console.error("Exception in getOrgDetails and err: ", err)
      }
    },
    /**
     * changePassword
     */
    async changePassword () {
      if (this.newPassword && this.token && this.confirmPassword) {
        if (this.newPassword === this.confirmPassword) {
          let profileEditResp = await User.userResetPassword(this, this.token, this.newPassword, this.otp, this.userEmail)
          await ApiResponse.responseMessageDisplay(this, profileEditResp)
          if (profileEditResp.resp_status) {
            this.$router.push("/login")
          }
        // if password doest match with confirm password
        }
        else {
          this.errorFlag = true
        }
      }
      else {
        this.errorFlag = true
      }
    }
  }
}
</script>
